import { HubbleError } from "~/components/error";
import { onMount } from "solid-js";
import { captureErrorInSentry } from "~/utils/third_party/sentry";

export default function NotFound() {
  onMount(() => {
    captureErrorInSentry(new Error("404 not found"));
  });
  return <HubbleError is404={true} errorMessage={"This page does not exist"} />;
}
